// 外事业务类型
export const foreignAffairsCategoryList = [
  {
    value: 1,
    label: "Issue New Iqama 暂住证新办",
  },
  {
    value: 2,
    label: "Renew Iqama 暂住证延期",
  },
  {
    value: 3,
    label: "Single Exit Reentry Visa 单次往返签证",
  },
  {
    value: 4,
    label: "Multiple Exit Reentry Visa 多次往返签证",
  },
  {
    value: 5,
    label: "Extend Exit Reentry Visa 往返签证延期",
  },
  {
    value: 6,
    label: "Issue Busniess Visit Visa 商务访签办理",
  },
  {
    value: 7,
    label: "Busniess Visit Visa Extension 商务访签延期",
  },
  {
    value: 8,
    label: "Issue Working Visit Visa 工作访签办理",
  },
  {
    value: 9,
    label: "Wroking Visit Visa Extension 工作访签延期",
  },
  {
    value: 10,
    label: "Issue Temporary Working Visa 临时工作签证办理",
  },
  {
    value: 11,
    label: "Temporary Wroking Visa Extension 临时工作签证延期",
  },
  {
    value: 12,
    label: "Transfer Of Sponsorship 更换签证担保单位",
  },
  {
    value: 13,
    label: "Issue Visit Non-Working Country 第三国签证",
  },
  {
    value: 14,
    label: "Update Passport 护照信息更新",
  },
  {
    value: 15,
    label: "Cancel IQAMA 暂住证注销",
  },
  {
    value: 16,
    label: "Issue Exit Visa 离境签证办理",
  },
  {
    value: 17,
    label: "Ccancel Exit Visa 离境签证注销",
  },
  {
    value: 18,
    label: "Change IQAMA Profession IQAMA 更改职位",
  },
];
